import "../style/css/Simulator.css";
import $ from "jquery";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const API_URL = "https://smog-detector-and-alert-system.el.r.appspot.com/api/";

const Simulator = () => {
	const [detectorData, setDetectorData] = useState({
		mq135: "",
		mq137: "",
		mq131: "",
		mq2: "",
		visibility: "",
		location: "",
	});
	const { mq135, mq137, mq131, mq2, visibility, location } = detectorData;
	const setDetector = async () => {
		let data = {
			mq135: mq135,
			mq137: mq137,
			mq131: mq131,
			mq2: mq2,
			visibility: visibility,
			location: location,
		};
		try {
			const response = await axios.post(API_URL + "detector/set", data);
			if (!response) {
				alert("Something went wrong");
				return;
			}
		} catch (error) {
			alert(error.data.message);
		}
	};
	const onChange = (e) => {
		setDetectorData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};
	useEffect(() => {
		setDetector();
	}, [mq135, mq137, mq131, mq2, visibility]);

	return (
		<div id="simulator-container">
			<h2>
				Arduino Simulator <br />
				Smog Detector and Alert System <br />
			</h2>
			<label id="location-text">Location:</label>
			<input value={location} type="text" onChange={onChange} name="location" />
			<button onClick={setDetector}>Upload</button>
			<div id="arduino-root-container">
				<div id="arduino-wrapper">
					<div id="arduino">
						<div id="mq135">
							<p>MQ135</p>
							<input
								value={mq135}
								type="number"
								onChange={onChange}
								name="mq135"
							/>
						</div>
						<div id="mq137">
							<p>MQ137</p>
							<input
								value={mq137}
								type="number"
								onChange={onChange}
								name="mq137"
							/>
						</div>
						<div id="mq131">
							<p>MQ131</p>
							<input
								value={mq131}
								type="number"
								onChange={onChange}
								name="mq131"
							/>
						</div>
						<div id="mq2">
							<p>MQ2</p>
							<input value={mq2} type="number" onChange={onChange} name="mq2" />
						</div>
						<div id="visibility-sensor">
							<p>Visibility</p>
							<input
								value={visibility}
								type="number"
								onChange={onChange}
								name="visibility"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Simulator;
