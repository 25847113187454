import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "../src/style/css/reset.css";
import "../src/style/css/common.css";
import Homepage from "./components/Homepage";
import Simulator from "./components/Simulator";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("main-wrapper"));
root.render(
	<BrowserRouter>
		<React.StrictMode>
			<Routes>
				<Route path="*" element={<Homepage />} />
				<Route path="/sim" element={<Simulator />} />
			</Routes>
		</React.StrictMode>
	</BrowserRouter>
);
reportWebVitals();
