import "../style/css/Homepage.css";
import $ from "jquery";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
const API_URL = "https://smog-detector-and-alert-system.el.r.appspot.com/api/";
const Homepage = () => {
	const [myLocation, setMyLocation] = useState("");
	const [detectorData, setDetectorData] = useState({
		smogValue: "",
		visibilityValue: "",
		safeSpeedValue: "",
		ammoniaValue: "",
		ozoneValue: "",
		smokeValue: "",
		location: "",
	});
	const {
		smogValue,
		visibilityValue,
		safeSpeedValue,
		ammoniaValue,
		ozoneValue,
		smokeValue,
	} = detectorData;
	const fetchData = async () => {
		let data = {
			location: 1,
		};
		try {
			const response = await axios.post(API_URL + "detector/get", data);
			if (!response) {
				alert("Something went wrong");
				return;
			}
			updateValues(response.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		const interval = setInterval(() => fetchData(), 1000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	const updateValues = (data) => {
		let mq135 = data.mq135;
		let mq137 = data.mq137;
		let mq131 = data.mq131;
		let mq2 = data.mq2;
		let visibility = data.visibility;
		setDetectorData((prevState) => ({
			...prevState,
			smogValue: mq135,
			visibilityValue: visibility,
			ammoniaValue: mq137,
			ozoneValue: mq131,
			smokeValue: mq2,
		}));
	};
	useEffect(() => {
		let percentage = 100 - Math.round((Number(smogValue) / 300) * 100);
		let perc = percentage + "%";
		$("#smog-level-cover").css({ width: perc });
		$(".high-smog-symptom").hide();
		$(".medium-smog-symptom").hide();
		$(".low-smog-symptom").show();
		if (smogValue < 100) {
			$("#smog-level-text").text("Low");
			setDetectorData((prevState) => ({
				...prevState,
				safeSpeedValue: ">80",
			}));
		} else if (smogValue < 200) {
			$("#smog-level-text").text("Medium");
			$(".high-smog-symptom").hide();
			$(".medium-smog-symptom").show();
			$(".low-smog-symptom").show();
			setDetectorData((prevState) => ({
				...prevState,
				safeSpeedValue: "<60",
			}));
		} else {
			$("#smog-level-text").text("High");
			$(".high-smog-symptom").show();
			$(".medium-smog-symptom").show();
			$(".low-smog-symptom").show();
			setDetectorData((prevState) => ({
				...prevState,
				safeSpeedValue: "<30",
			}));
		}
	}, [smogValue]);
	return (
		<div id="main-container">
			<div id="root">
				<h1 id="title">Smog Detector</h1>
				<div id="smog-level-container">
					<h2 id="smog-level-label">
						Smog level <span id="smog-level-text"></span>
					</h2>
					<div id="smog-level">
						<p id="smog-value">{smogValue}</p>

						<div id="smog-level-cover"></div>
					</div>
				</div>
				<div id="visibility-level-container">
					<div className="subsection">
						<p className="subsection-title">Visibility</p>
						<p className="subsection-value">
							<span id="visibility-value">{visibilityValue}</span> Meters
						</p>
					</div>
					<div className="subsection">
						<p className="subsection-title">Safe Speed</p>
						<p className="subsection-value">
							<span id="safe-speed-value">{safeSpeedValue}</span> KM/H
						</p>
					</div>
				</div>
				<div id="gases-concentration-container">
					<div className="subsection">
						<p className="subsection-title">Ammonia</p>
						<p className="subsection-value">
							<span id="ammonia-value">{ammoniaValue}</span> PPM
						</p>
					</div>
					<div className="subsection">
						<p className="subsection-title">Ozone</p>
						<p className="subsection-value">
							<span id="ozone-value">{ozoneValue}</span> PPM
						</p>
					</div>
					<div className="subsection">
						<p className="subsection-title">Smoke</p>
						<p className="subsection-value">
							<span id="smoke-value">{smokeValue}</span> PPM
						</p>
					</div>
				</div>
				<div id="disease-container">
					<h2>Warning</h2>
					<p className="high-smog-symptom">
						Asthma patient please stay inside.
					</p>
					<p className="medium-smog-symptom">
						Smog level can cause eyes and nose irritation.
					</p>
					<p className="medium-smog-symptom">
						Today, you may feel eyes burning sensation.
					</p>
					<p className="low-smog-symptom">Current wether can cause coughing.</p>
					<p className="high-smog-symptom">Damage to lung can happen.</p>
					<p className="medium-smog-symptom">Throat irritation can happen.</p>
					<p className="high-smog-symptom">Elder citizen stay inside.</p>
					<p className="medium-smog-symptom">
						Respiratory tract can cause irritation.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Homepage;
